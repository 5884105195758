<template>
  <v-container>
    <title-page icon="mdi-alarm">
      {{ $t('alerts.alerts') }}
    </title-page>

    <v-row class="my-5">
      <v-col class="text-right">
        <v-btn @click="addAlert">
          <v-icon left>mdi-alarm-plus</v-icon>
          {{ $t('alerts.add')}}
        </v-btn>
      </v-col>
    </v-row>



    <v-data-table
      :headers="headers"
      :items="alerts"
      :loading="loading"
    >
      <template #item.organizations="{item}">
        <template v-if="item.organizations.length == 0">{{ $t('all') }}</template>
        <template v-for="(o, i) in item.organizations">
          <template v-if="i > 0">, </template>{{ o.name }}
        </template>
      </template>

      <template #item.createdBy="{item}">
        {{ item.createdBy.firstname }} {{ item.createdBy.lastname }}
      </template>


      <template #item.recipients="{item}">
        {{ item.mailRecipients.length + item.smsRecipients.length }}<br />
      </template>


      <template #item.createdAt="{item}">
        {{ item.createdAt|formatDate }}
      </template>


      <template #item.options="{item}">
        <v-btn @click.prevent="editAlert(item)" small class="mr-3" icon>
          <v-icon>mdi-pencil</v-icon>
        </v-btn>


        <v-btn @click.prevent="delAlert(item)" small icon>
          <v-icon>mdi-delete</v-icon>
        </v-btn>

      </template>
    </v-data-table>

    <alert-form-modal ref="alertFormModal" :alert="selectedAlert" @saved="onSaved" />

  </v-container>
</template>

<script>
import AlertFormModal from "@pages/Alert/modals/AlertFormModal";
import AlertRepository from "@repository/AlertRepository";

export default {
  components: {
    AlertFormModal
  },

  data() {
    return {
      loading: false,
      selectedAlert: null,
      alerts: []
    }
  },

  mounted() {
    this.loadData()
  },

  computed: {
    headers() {
      return [
        {text: this.$t('name'), value: 'name', width: '30%'},
        {text: this.$t('organization.organization'), value: 'organizations'},
        {text: this.$t('alerts.delivery.recipients-count'), value: 'recipients'},
        {text: this.$t('created-by'), value: 'createdBy'},
        {text: this.$t('created-at'), value: 'createdAt'},
        {text: '', value: 'options', width: 140},
      ]
    }
  },

  methods: {

    loadData() {

      this.loading = true

      let data = {}
      AlertRepository.search(data).then(response => {
        this.alerts = response
        this.loading = false
      }).catch(error => {
        this.$dialog.notify.error(error.response?.data?.message ?? error.message)
        this.loading = false
      })
    },

    addAlert() {
      this.selectedAlert = null
      this.$nextTick(() => this.$refs.alertFormModal.open())

    },


    editAlert(item) {
      this.selectedAlert = item
      this.$nextTick(() => this.$refs.alertFormModal.open())
    },


    delAlert(item) {
      this.$dialog.confirm({text: this.$t('are-you-sure')}).then(result => {
        if(result) {
          this.loading = true
          AlertRepository.delete(item.id).then(response => {
            this.loadData()
          }).catch(error => {
            this.$dialog.notify.error(error.response?.data?.message ?? error.message)
            this.loading = false
          })
        }
      })
    },

    onSaved() {
      this.loadData()
    }
  }

}
</script>

<style lang="scss">

</style>