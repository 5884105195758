<template>
  <v-dialog v-model="dialog" max-width="1000" scrollable v-if="form">
    <v-form @submit.prevent="save" ref="form">
      <v-card>
        <v-card-title class="mx-3">
          {{ $t(edit ? 'alerts.edit' : 'alerts.add') }}
        </v-card-title>

        <v-divider />

        <v-card-text>

          <div class="mx-3">
            <v-row dense>
              <v-col cols="12">
                <v-text-field
                    type="string"
                    :label="$t('alerts.name')"
                    v-model="form.name"
                    :rules="[commonRules.required]"
                />
              </v-col>
            </v-row>
            <v-row dense>
              <v-col cols="12">
                <v-textarea
                    :label="$t('alerts.description')"
                    v-model="form.description"
                    auto-grow
                    rows="1"
                />
              </v-col>
            </v-row>

            <headline class="mt-6">{{ $t('alerts.rules.rule') }}</headline>
            <v-row>
              <v-col cols="4">
                <v-select
                    type="select"
                    :label="$t('alerts.rules.trigger-type')"
                    v-model="form.type"
                    :items="triggerTypes"
                    hide-details
                />
              </v-col>
              <v-col cols="1">
                <v-select
                    type="select"
                    :label="$t('alerts.rules.condition')"
                    v-model="form.conditionType"
                    :items="conditionTypes"
                    hide-details
                />
              </v-col>
              <v-col>

                <v-select
                    v-if="form.type === 'EVSE_STATUS'"
                    type="select"
                    multiple
                    :label="$t('enums.alert-types.' + form.type)"
                    v-model="form.conditionValues"
                    :items="statusTypes"
                    chips deletable-chips
                    hide-details
                />

                <v-select
                    v-else-if="form.type === 'ERROR_CODE'"
                    type="select"
                    multiple
                    :label="$t('enums.alert-types.' + form.type)"
                    v-model="form.conditionValues"
                    :items="errorCodeTypes"
                    chips deletable-chips
                    hide-details
                    ref="selectErrorCode"
                    small-chips
                    :menu-props="{
                      maxHeight: 500,
                    }"
                >
                  <template #prepend-item>
                    <div class="v-select-prepend-fixed">
                      <v-row class="ma-1">
                        <v-col class="text-center">
                          <v-btn x-small @click="form.conditionValues = errorCodeTypes.map(e => e.value)">
                            {{ $t('select-all') }}
                          </v-btn>
                        </v-col>
                        <v-divider vertical />
                        <v-col class="text-center">
                          <v-btn x-small @click="form.conditionValues = []">
                            {{ $t('unselect-all') }}
                          </v-btn>
                        </v-col>
                      </v-row>
                      <v-divider />
                    </div>
                  </template>

                  <template #append-item>
                    <div class="v-select-append-fixed">
                      <v-divider />
                      <v-row class="ma-1">
                        <v-col class="text-center">
                          <v-btn x-small @click="$refs.selectErrorCode.blur()">
                            {{ $t('close') }}
                          </v-btn>
                        </v-col>
                      </v-row>
                    </div>
                  </template>

                </v-select>

                <v-text-field
                    v-else
                    type="number"
                    :label="$t('enums.alert-types.' + form.type)"
                    v-model.number="form.conditionValue"
                    :suffix="ruleValueSuffix"
                    hide-details
                />
              </v-col>

            </v-row>


            <headline class="mt-6">{{ $t('alerts.sources') }}</headline>
            <v-row>
              <v-col>
                <tree-select-organization
                    v-model="form.organizationIds"
                    multiple
                />
              </v-col>
            </v-row>

            <headline class="mt-6">{{ $t('alerts.delivery.notifications') }}</headline>
            <v-row>
              <v-col>
                <v-select
                    type="select"
                    :label="$t('alerts.delivery.frequency')"
                    v-model="form.deliveryDelay"
                    :items="deliveryDelayTypes"
                    hide-details
                />
              </v-col>
            </v-row>

            <v-row>
              <v-col>
                <v-form ref="addMailForm" @submit.prevent="addMail">
                  <v-text-field
                      :label="$t('alerts.delivery.mail-recipients')"
                      v-model="tmpMail"
                      :rules="[
                      commonRules.email,
                    ]"
                      clearable
                  >
                    <template #append-outer>
                      <v-btn color="primary" x-small type="submit">{{ $t('add') }}</v-btn>
                    </template>
                  </v-text-field>
                </v-form>

                <div class="chips-wrapper">
                  <v-chip v-for="(item, i) in form.mailRecipients" :key="'chip-mail-' + i" close @click:close="delMail(item)">
                    {{ item }}
                  </v-chip>
                </div>

              </v-col>
              <v-col>
                <v-form @submit.prevent="addPhone">
                  <vue-tel-input-vuetify
                      ref="phoneInput"
                      :label="$t('alerts.delivery.sms-recipients')"
                      :placeholder="$t('enter-phone-number')"
                      valid-characters-only
                      @input="validatePhoneInput"
                      :messages="tmpPhoneError"
                      v-model="tmpPhone"
                      clearable
                  >

                    <template #append-outer>
                      <v-btn color="primary" x-small type="submit">{{ $t('add') }}</v-btn>
                    </template>
                  </vue-tel-input-vuetify>
                </v-form>

                <div class="chips-wrapper">
                  <v-chip v-for="(item, i) in form.smsRecipients" :key="'chip-phone-' + i" close @click:close="delPhone(item)">
                    {{ item }}
                  </v-chip>
                </div>


              </v-col>
            </v-row>
          </div>
        </v-card-text>

        <v-divider />

        <v-card-actions  class="mx-3">
          <v-row>
            <v-col class="text-right">
              <v-btn @click.prevent="close" class="mr-4">
                {{ $t('cancel') }}
              </v-btn>

              <v-btn type="submit" color="primary" :loading="loading" :disabled="loading">
                {{ $t(edit ? 'save' : 'create') }}
              </v-btn>
            </v-col>
          </v-row>
        </v-card-actions>
      </v-card>
    </v-form>
  </v-dialog>
</template>

<script>
import TreeSelectOrganization from "@blocks/Select/TreeSelectOrganization.vue";
import RulesMixin from "@mixins/RulesMixin";
import AlertRepository from "@repository/AlertRepository";

export default {
  components: {TreeSelectOrganization},

  mixins: [RulesMixin],

  data() {


    return {
      loading: false,
      dialog: false,
      firstLoading: true,
      form: null,
      tmpPhone: null,
      tmpValidPhone: null,
      tmpPhoneError: [],
      tmpMail: null,
    }
  },

  props: {
    alert: Object
  },

  mounted() {
    this.resetForm()
  },

  watch: {
    'form.type': function(val) {
      if(!this.firstLoading) {
        this.form.conditionType = '='
        this.form.conditionValue = null
        this.form.conditionValues = []
      }
    },

    dialog(val) {
      if(!val) {
        this.resetForm()
      }
    },

  },

  computed: {
    edit() {
      return !!this.alert
    },

    isConditionValueIsArray() {
      return ['EVSE_STATUS', 'ERROR_CODE'].includes(this.form.type)
    },

    triggerTypes() {
      return [
        {text: this.$t('enums.alert-types.EVSE_STATUS'), value: 'EVSE_STATUS'},
        {text: this.$t('enums.alert-types.ERROR_CODE'), value: 'ERROR_CODE'},
        {text: this.$t('enums.alert-types.SESSION_DURATION'), value: 'SESSION_DURATION'},
        {text: this.$t('enums.alert-types.SESSION_ENERGY'), value: 'SESSION_ENERGY'},
        {text: this.$t('enums.alert-types.SESSION_POWER'), value: 'SESSION_POWER'},
        {text: this.$t('enums.alert-types.SESSION_PRICE'), value: 'SESSION_PRICE'},
      ]
    },

    statusTypes() {
      return [
        {text: this.$t('enums.alert-status.OFFLINE'), value: 'OFFLINE'},
        {text: this.$t('enums.alert-status.UNAVAILABLE'), value: 'UNAVAILABLE'},
        {text: this.$t('enums.alert-status.FAULTED'), value: 'FAULTED'},
      ]
    },

    deliveryDelayTypes() {
      return [
        {text: this.$t('enums.alert-delivery-frequencies.IMMEDIATELY'), value: 'IMMEDIATELY'},
        {text: this.$t('enums.alert-delivery-frequencies.HOURLY'), value: 'HOURLY'},
        {text: this.$t('enums.alert-delivery-frequencies.EVERY_6HOURS'), value: 'EVERY_6HOURS'},
        {text: this.$t('enums.alert-delivery-frequencies.EVERY_12HOURS'), value: 'EVERY_12HOURS'},
        {text: this.$t('enums.alert-delivery-frequencies.DAILY'), value: 'DAILY'},
      ]
    },

    conditionTypes() {
      let types = [
        {text: '=', value: '='},
      ]

      if(!this.isConditionValueIsArray) {
        types.push({text: '<', value: '<'})
        types.push({text: '>', value: '>'})
        types.push({text: '<=', value: '<='})
        types.push({text: '>=', value: '>='})
      }

      return types
    },

    errorCodeTypes() {
      return [
        {text: 'SuspendedEV', value: 'SuspendedEV'},
        {text: 'SuspendedEVSE', value: 'SuspendedEVSE'},
        {text: 'ConnectorLockFailure', value: 'ConnectorLockFailure'},
        {text: 'EVCommunicationError', value: 'EVCommunicationError'},
        {text: 'GroundFailure', value: 'GroundFailure'},
        {text: 'HighTemperature', value: 'HighTemperature'},
        {text: 'InternalError', value: 'InternalError'},
        {text: 'LocalListConflict', value: 'LocalListConflict'},
        {text: 'NoError', value: 'NoError'},
        {text: 'OtherError', value: 'OtherError'},
        {text: 'OverCurrentFailure', value: 'OverCurrentFailure'},
        {text: 'PowerMeterFailure', value: 'PowerMeterFailure'},
        {text: 'PowerSwitchFailure', value: 'PowerSwitchFailure'},
        {text: 'ReaderFailure', value: 'ReaderFailure'},
        {text: 'ResetFailure', value: 'ResetFailure'},
        {text: 'UnderVoltage', value: 'UnderVoltage'},
        {text: 'OverVoltage', value: 'OverVoltage'},
        {text: 'WeakSignal', value: 'WeakSignal'},
      ]
    },

    ruleValueSuffix() {
      switch(this.form.type) {
        case 'SESSION_DURATION':
          return this.$t('seconds')
        case 'SESSION_ENERGY':
          return this.$t('Wh')
        case 'SESSION_POWER':
          return this.$t('W')
        case 'SESSION_PRICE':
          return this.$t('€')
        default:
          return null
      }
    },
  },

  methods: {

    resetForm() {
      this.firstLoading = true;
      this.form = {
        name: "",
        description: "",
        type: 'EVSE_STATUS',
        conditionType: '=',
        conditionValues: ['OFFLINE'],
        conditionValue: null,
        organizationIds: [],
        deliveryDelay: 'IMMEDIATELY',
        mailRecipients: [],
        smsRecipients: [],
      }
    },

    open() {
      if(this.alert) {
        this.form = this.cloneObj(this.alert)
      }
      this.$nextTick(() => this.$refs.form?.resetValidation())
      this.dialog = true
      this.$nextTick(() => this.firstLoading = false)
    },

    close() {
      this.resetForm()
      this.dialog = false
    },

    addMail() {
      if(this.$refs.addMailForm.validate()) {
        let value = this.tmpMail?.trim()
        if(value) {
          if(this.form.mailRecipients.filter(v => v === value).length === 0) {
            this.form.mailRecipients.push(value)
          }
          this.$refs.addMailForm.reset()
        }
      }
    },

    delMail(value) {
      this.form.mailRecipients = this.form.mailRecipients.filter(v => v !== value)
    },

    addPhone() {
      let value = this.tmpValidPhone
      if(value) {
        if(this.form.smsRecipients.filter(v => v === value).length === 0) {
          this.form.smsRecipients.push(value)
        }
        this.tmpPhone = null
        this.tmpValidPhone = null
        this.tmpPhoneError = []
      }
    },

    delPhone(value) {
      this.form.smsRecipients = this.form.smsRecipients.filter(v => v !== value)
    },

    validatePhoneInput(formattedNumber, obj) {
      if(this.tmpPhone) {
        if(obj.valid) {
          this.tmpValidPhone = obj.number.international
          this.tmpPhoneError = []
        } else {
          this.tmpValidPhone = null
          this.tmpPhoneError = [this.$t('phone-number-not-valid')]
        }
      } else {
        this.tmpValidPhone = null
        this.tmpPhoneError = []
      }
    },

    save() {

      if(this.$refs.form.validate()) {
        this.loading = true
        if(this.edit) {
          AlertRepository.update(this.alert.id, this.form)
              .then(() => {
                this.$emit('saved')
                this.loading = false
                this.$dialog.notify.success(this.$t('updated-successfully'))
                this.close()
              })
              .catch(error => {
                this.$dialog.notify.error(error.response?.data?.message ?? error.message)
                this.loading = false
              });
        } else {
          AlertRepository.create(this.form)
              .then(() => {
                this.$emit('saved')
                this.loading = false
                this.$dialog.notify.success(this.$t('created-successfully'))
                this.close()
              })
              .catch(error => {
                this.$dialog.notify.error(error.response?.data?.message ?? error.message)
                this.loading = false
              });
        }
      }
    },


  }
}
</script>

<style lang="scss">
  .chips-wrapper {
    .v-chip {
      margin-bottom: 10px;
      & + .v-chip {
        margin-left: 10px;
      }
    }
  }


</style>
